import { produce } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useWwmStore = create(
  devtools(
    (set, get) => ({
      wwm: {
        hostToken: null,
        userProps: null,
        operatorId: null,
        shortUrl: null,
        leid: null
      },
      contentGroup: {
        contentGroupId: null,
        actions: null,
        marks: null
      },
      chatbot: {
        followUpQuestions: []
      },
      setHostToken: token =>
        set(
          produce(state => {
            state.wwm.hostToken = token;
          })
        ),
      setUserProps: props =>
        set(
          produce(state => {
            state.wwm.userProps = props;
          })
        ),
      setOperatorId: operatorId =>
        set(
          produce(state => {
            state.wwm.operatorId = operatorId;
          })
        ),
      setShortUrl: shortUrl =>
        set(
          produce(state => {
            state.wwm.shortUrl = shortUrl;
          })
        ),
      setLeid: leid =>
        set(
          produce(state => {
            state.wwm.leid = leid;
          })
        ),

      setContentGroup: contentGroup =>
        set(
          produce(state => {
            if (contentGroup.contentGroupId === "manual") {
              state.contentGroup.contentGroupId = contentGroup.contentGroupId;
              state.contentGroup.actions = [];
              state.contentGroup.marks = contentGroup.marks;
              state.chatbot.followUpQuestions = contentGroup.actions.map(action => ({
                label: action.name,
                question: action.args.message
              }));
            } else {
              state.contentGroup = contentGroup;
            }
          })
        ),

      setFollowUpQuestions: followUpQuestions =>
        set(
          produce(state => {
            state.chatbot.followUpQuestions = followUpQuestions;
          })
        ),
      isSalesDemo: () => {
        return get().wwm.userProps?.edge === "https://m10tv.com:4446" ?? false;
      }
    }),
    { name: "wwmStore", enabled: true }
  )
);

export default useWwmStore;
